import React, { Component } from 'react';
import portraitface from './images/portrait-footer.png';
import starwhite from './images/star-white.png';
import underdevtxt from './images/under-dev-txt.png';
import spaceship from './images/spaceship.png';
import floorpanel from './images/floor-panel.png';
import ironblocks from './images/iron-blocks.png';
import layoutabouttext from './images/layout-about.png';
import building1 from './images/building1.png';
import building2 from './images/building2.png';
import himanshutext from './images/himanshu-text.png';
import moon from './images/moon.png';
import spaceyfwd from './images/astro-sprite.png';
import spaceybwd from './images/astro-sprite-left.png';
import spaceyrt from './images/spaceywchat.png';
import spaceylt from './images/spacey-left.png';
import legoballoon from './images/lego-balloon.png';
import './resume.css';
import { findDOMNode } from 'react-dom';
import $ from 'jquery';

class Resume extends Component{
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    //window.callbackFn();
    var isMovingRight = false;

    setTimeout(function(){
        $(".control-text").hide();
    }, 20000);

    // to add touch events
    (function(d){
         var
         ce=function(e,n){var a=document.createEvent("CustomEvent");a.initCustomEvent(n,true,true,e.target);e.target.dispatchEvent(a);a=null;return false},
         nm=true,sp={x:0,y:0},ep={x:0,y:0},
         touch={
          touchstart:function(e){sp={x:e.touches[0].pageX,y:e.touches[0].pageY}},
          touchmove:function(e){nm=false;ep={x:e.touches[0].pageX,y:e.touches[0].pageY}},
          touchend:function(e){if(nm){ce(e,'fc')}else{var x=ep.x-sp.x,xr=Math.abs(x),y=ep.y-sp.y,yr=Math.abs(y);if(Math.max(xr,yr)>20){ce(e,(xr>yr?(x<0?'swl':'swr'):(y<0?'swu':'swd')))}};nm=true},
          touchcancel:function(e){nm=false}
         };
         for(var a in touch){d.addEventListener(a,touch[a],false);}
    })(document);

    function showControlText() {
        $(".control-text").show();
    }

    function handleVertical(layer, movement, vpOffset) {
        var translate3d = 'translate3d(' + vpOffset + 'px ,'  + movement + 'px, 0)';

        layer.style['-webkit-transform'] = translate3d;
        layer.style['-moz-transform'] = translate3d;
        layer.style['-ms-transform'] = translate3d;
        layer.style['-o-transform'] = translate3d;
        layer.style.transform = translate3d; 

    }
    function initializeHorizontalLayers() {
        var depth, i, layer, layers, len;
        layers = document.getElementsByClassName("horizontal-layer");
        for (i = 0, len = layers.length; i < len; i++) {
            layer = layers[i];
            var leftOffset = layer.getAttribute('data-leftoffset');
            var bottomOffset = layer.getAttribute('data-bottomoffset');
            var topOffset = layer.getAttribute('data-topoffset');
            var OffsetObj = {
                left : leftOffset
            }
            $(layer).offset(OffsetObj);
            if(topOffset){
                $(layer).css({top : topOffset + "px" });
            }
            if(bottomOffset){
                $(layer).css({bottom : bottomOffset + "px" });
            }
        }
    }

    function handleSwipeOrKeyPress(event) {
        var depth, i, layer, layers, len, movement, absoluteDistance;

        absoluteDistance = 100;
        layers = document.querySelectorAll("[data-type='parallax']");

        var mult = (event.keyCode == 40 || event.type == 'swr') ? 1 : event.keyCode == 38 || event.type == 'swl' ?  -1 : 0;

        for (i = 0, len = layers.length; i < len; i++) {
          layer = layers[i];
          depth = layer.getAttribute('data-depth');

          if(mult == -1){
            $("#spacey-right").show();
            $("#spacey-left").hide(); 
          } else if(mult==1){
            $("#spacey-right").hide();
            $("#spacey-left").show();
          }
          if(event.type == 'swr' || event.type == 'swl'){
            mult = mult > 0 ? 10 : mult < 0 ? -10 : 0;
            var newOffset = $(layer).offset().left + absoluteDistance * depth * mult;

            if(mult == 10 && newOffset > layer.getAttribute('data-leftoffset')){
              break;
            }  
            $(layer).animate({
                left : newOffset
            }, 350, 'linear');
          } else {
              var newOffset = $(layer).offset().left + absoluteDistance * depth * mult;
              if(mult == 1 && newOffset > layer.getAttribute('data-leftoffset')){
                continue;
              }  
              $(layer).offset({left : newOffset});
          }
        }
        if(mult < 0 && isMovingRight == false) {
          isMovingRight = true;
          AnimateStripe(0, $("#spacey-right"), 330, 7);
        }
        if(mult > 0 && isMovingRight == false) {
          isMovingRight = true;
          AnimateStripe(0, $("#spacey-left"), 330, 7);  
        }
    };
    
    var AnimateStripe = function(i, ele, height, n) {
        if(i == n+1){
            ele.css({"background-position" : "0px 0px" });
            isMovingRight = false;
            return;
        }
        setTimeout(function() {
            ele.css({"background-position" : "0px " + (i*height)+"px" });
            AnimateStripe(i+1, ele, height, n);
        }, 40);
    }

    var bodyWidth = document.body.clientWidth;
    var bodyHeight = document.body.clientHeight;

    var placeStarsRandomly = function (star, maxHeight) {
        var bodyWidth = document.body.clientWidth;
        var bodyHeight = document.body.clientHeight;
        star.each(function() {
            var ele = $(this);
            var height = Math.floor((Math.random()*maxHeight) + 3);
            var randPosX = Math.floor((Math.random()* bodyWidth) * 3);
            var randPosY = Math.floor((Math.random()*bodyHeight * 6));

            ele.height(height);
            ele.css('left', randPosX + "px");
            ele.css('top', randPosY);
        });  
    }
    
    placeStarsRandomly($(".staticstar"), 16);
    initializeHorizontalLayers();
    setTimeout(function(){
        document.body.addEventListener('swl',handleSwipeOrKeyPress,false);
        document.body.addEventListener('swr',handleSwipeOrKeyPress,false);
        document.onkeydown = handleSwipeOrKeyPress;
    });
  }   

  renderStars(classNm, numDots) {
    var isWebkit = /Webkit/i.test(navigator.userAgent),
      isChrome = /Chrome/i.test(navigator.userAgent),
      isMobile = !!("ontouchstart" in window),
      isAndroid = /Android/i.test(navigator.userAgent),
      isIE = document.documentMode;

      var dotsCount = numDots;
      var dotsHtml = [];
       
      for (var i = 0; i < dotsCount; i++) {
        dotsHtml.push(<img className={classNm} src={starwhite}></img>);
      }
      return dotsHtml;
  }
  //fake comment.

  render() {
    return (
      <div className="Resume">
        <img id="underdev" src={underdevtxt}></img>

        <div className="horizontal-layer hlayer1 stars" data-depth='0.01' data-type='parallax' data-leftoffset='0' data-bottomoffset='20'>
            {this.renderStars("staticstar", 350)}
        </div>

        <div className="horizontal-layer hlayer2 buildings" data-depth='0.15' data-type='parallax' data-leftoffset='300' data-bottomoffset='20'>
            <img src={himanshutext}/>
        </div>

        <div className="horizontal-layer hlayer2 buildings" data-depth='0.2' data-type='parallax' data-leftoffset='6000' data-bottomoffset='200'>
            <img src={building1}/>
        </div>

        <div className="horizontal-layer hlayer2 buildings" data-depth='0.16' data-type='parallax' data-leftoffset='3500' data-bottomoffset='0'>
            <img src={legoballoon}/>
        </div>


        <div className="horizontal-layer hlayer3 layerfloorpanel" data-depth='0.3' data-type='parallax' data-leftoffset='0' data-bottomoffset='0'
            style={{"backgroundImage": "url(" + floorpanel + ")"}}>
        </div>

        <div className="horizontal-layer hlayer4 abouttext" data-depth='0.3' data-type='parallax' data-leftoffset='3000' data-bottomoffset='10'>
            <img src={layoutabouttext}/>
        </div>        
        
        <div className="horizontal-layer hlayer5 moon" data-depth='0.015' data-type='parallax' data-leftoffset='-300' data-topoffset='125'>
            <img src={moon} />
        </div> 

        <div className="horizontal-layer hlayer7 layerfloorpanel" data-depth='0.3' data-type='parallax' data-leftoffset='2200' data-bottomoffset='200'
            style={{"backgroundImage": "url(" + ironblocks + ")"}}>
        </div>

        <div className="horizontal-layer hlayer7 layerfloorpanel" data-depth='0.3' data-type='parallax' data-leftoffset='7000' data-bottomoffset='200'
            style={{"backgroundImage": "url(" + ironblocks + ")"}}>
        </div>

        <div className="horizontal-layer hlayer6 spaceship" data-depth='0.04' data-type='parallax' data-leftoffset='2400' data-bottomoffset='200'>
            <img src={spaceship}/>
        </div>

        <div id="spacey">
          <div class="tiwari" id = "spacey-right" style = {{"backgroundImage": "url(" + spaceyfwd + ")", width:"260px", height:"309px"}}>
          </div>
          <div class="tiwari" id = "spacey-left" style = {{"backgroundImage": "url(" + spaceybwd + ")", width:"260px", height:"309px"}}>
          </div>
        </div>
       <div className="social">
            <a target="_blank" href="https://facebook.com/xariniov9" className="icon-button facebook"><i className="icon-facebook"></i><span></span></a>
            <a target="_blank" href="https://linkedin.com/in/xariniov9" className="icon-button linkedin"><i className="icon-linkedin"></i><span></span></a>
            <a target="_blank" href="https://github.com/xariniov9/interactive-resume" className="icon-button github"><i className="icon-github"></i><span></span></a>
            <a target="_blank" href="https://codechef.com/users/xariniov9" className="icon-button code"><i className="icon-code"></i><span></span></a>
        </div>
        <img id="portrait-footer" src={portraitface}></img>
        <div className="control-text">
            <p>Use Up/Down arrow keys or swipe Left/Right</p>
        </div>
      </div>
    );
  }
}

export default Resume;
