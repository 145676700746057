import React, { Component } from 'react';
import './introcard.css';
import { findDOMNode } from 'react-dom';
import currencyicon from './images/currency-icon.png';
import durationicon from './images/duration-icon.png'
import livedot from './images/live.png';
import upcoming from './images/upcoming.png';
import $ from 'jquery';

class IntroCard extends Component{
  constructor(props) {
    super(props);
  }
  componentDidMount() {

  }
  render() {
		return (
      <div>
        <div className = "IntroCard MainContentCard">
          <div className = "MainContentCardTitle">
            <img className="inlineItem" src={upcoming}></img><h5 className="inlineItem red dot"> Upcoming </h5>
            <h1>Programming Fundamentals</h1>
            <h3 className = "lighterText">Pathway and Material</h3>
          </div>

          <div className="centerAlign">
            <hr className="coloredBar"></hr>
          </div>
          
          <div className = "MainContentCardBody">
            <p>
               Programming gives you the ability to digitize your ideas. Imagine being able to actually implement any idea that you could think about.
               Developers are in high demand. 
            </p>
            <br></br>
            <p>
              You just need a computer to get started. There's nothing a code can't do!
            </p>
            <br></br>
            <div className="centerAlign">
              <hr className="lightgreyBar"></hr>
            </div>
            <div className="PriceAndDuration">
              <img className="inlineItem" src={currencyicon}></img><h4 className="inlineItem lighterText"> Free </h4><img className="inlineItem" src={durationicon}></img><h4 className="inlineItem lighterText"> Self Paced</h4>
            </div>
          </div>
        </div>

        <div className = "CPCard MainContentCard">
          <div className = "MainContentCardTitle">
            <img className="inlineItem" src={livedot}></img><h5 className="inlineItem green dot"> Live </h5>
            <br></br>
            <h1>Competitive Programming</h1>
            <h3 className = "lighterText">Pathway and Material</h3>
          </div>

          <div className="centerAlign">
            <hr className="coloredBar"></hr>
          </div>
          
          <div className = "MainContentCardBody">
            <p>
              Competitive Programming happens to be the top skill helping people crack tough coding interviews. Lets Explore it together.
            </p>
            <br></br>
            <p>
              You should be familiar with basic constructs of a programming language before starting with this pathway. Contact <a href="https://facebook.com/xariniov9/">me</a> for details.
            </p>
            <br></br>
            <div className="centerAlign">
              <hr className="lightgreyBar"></hr>
            </div>
            <div className="PriceAndDuration">
              <img className="inlineItem" src={currencyicon}></img><h4 className="inlineItem lighterText"> Free </h4><img className="inlineItem" src={durationicon}></img><h4 className="inlineItem lighterText"> Self Paced</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IntroCard;
