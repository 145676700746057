import React, { Component } from 'react';
import './codewith.css';
import Header from './header/header';
import { findDOMNode } from 'react-dom';
import StaticBg from './content-static/static-bg/staticbg';
import IntroCard from './content-static/content-main/introcard';
import $ from 'jquery';

class CodeWith extends Component{
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    console.log("code with!")
  }
  render() {
		return (
      <div className="main-app">
        <Header/>
        <IntroCard/>
        <StaticBg/>
      </div>
    );
  }
}

export default CodeWith;
