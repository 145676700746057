import React, { Component } from 'react';
import './App.css';
import Resume from './components/resume/resume';
import CodeWith from './components/codewith/codewith';
import { findDOMNode } from 'react-dom';
import $ from 'jquery';

class App extends Component{
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }
  //fake comment.

  render() {
    return (
        <h1>Hi</h1>
      )
  }
}

export default App;
