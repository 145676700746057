import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';

class IntroCard extends Component{
  constructor(props) {
    super(props);
  }
  componentDidMount() {

  }
  render() {
		return (
        <div className = "IntroCard MainContentCard">
          <div className = "MainContentCardTitle">
            <h1>Uh oh! Not Found!</h1>
          </div>
          <div className="centerAlign">
            <hr className="coloredBar"></hr>
          </div>
          <div className = "MainContentCardBody">
            <p>
              Sorry! We cannot show you what you are looking for.
            </p>
            <p>
              
            </p>
          </div>
        </div>
    )
  }
}

export default IntroCard;
