import React, { Component } from 'react';
import './staticbg.css';
import { findDOMNode } from 'react-dom';
import laptop from './images/laptop.png';
import $ from 'jquery';

class StaticBg extends Component{
  constructor(props) {
    super(props);
  }
  componentDidMount() {

  }
  render() {
		return (
        <div className = "staticBg">
          <img id="laptopImg" src = {laptop}></img>
        </div>
    )
  }
}

export default StaticBg;
