import React, { Component } from 'react';
import './header.css';
import codewithlogo from './images/code-with-logo.png';
import { findDOMNode } from 'react-dom';
import $ from 'jquery';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

var firebaseConfig = {
  apiKey: "AIzaSyBoCbCtG9s2q5wKGvxGv2tLSPXY14Ap3ys",
  authDomain: "himanshu-tiwari.firebaseapp.com",
  databaseURL: "https://himanshu-tiwari.firebaseio.com",
  projectId: "himanshu-tiwari",
  storageBucket: "himanshu-tiwari.appspot.com",
  messagingSenderId: "1091825633305",
  appId: "1:1091825633305:web:3c0e13320b5856c1"
};
firebase.initializeApp(firebaseConfig);


class Header extends Component{

  state = {
    isSignedIn: false // Local signed-in state.
  };
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {this.setState({isSignedIn: !!user}); console.log(user);}
        
    );
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  render() {
		if (!this.state.isSignedIn) {
      return (
        <div>
          <div className = "Header">
            <img id="codewithlogo" src={codewithlogo}></img>
            <div id="button-sign-in">
              <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className = "Header">
          <img id="codewithlogo" src={codewithlogo}></img>
          <div className="loggedInUserDetails">
            <div className="displayPhoto">
              <img id="loggedInUserImage" src={firebase.auth().currentUser.photoURL}></img>
            </div>
            <div className="displayName">
              <p>Hi {firebase.auth().currentUser.displayName}!</p>
            </div>
          </div>
          <div className="signoutBtn">
            <a className="btnClrd orange" onClick={() => firebase.auth().signOut()} >Sign out</a>
          </div>
        </div>
      </div>
    );
	}
}

export default Header;